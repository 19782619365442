import "./index.css";

function Loader() {
  return (
    <div className="flex flex-col gap-[100px] items-center justify-center w-full h-full">
      <div className="boxes">
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="box">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <p>Please wait ...</p>
    </div>
  );
}

export default Loader;
