import { useState } from "react";
import KeyboardKey from "../KeyboardKey/KeyboardKey";
import keyboardLayout from "./keyboardLayout";
import Modal from "../Modal/Modal";

function Keyboard() {
  const [capsLock, setCapLock] = useState(false);
  const [showModal, setShowModal] = useState(true);
  const [shift, setShift] = useState(false);
  window.addEventListener("keydown", (e) => {
    setCapLock(e.getModifierState("CapsLock"));
    if (e.key === "Shift" && !shift) setShift(true);
  });
  window.addEventListener("keyup", (e) => {
    if (e.key === "Shift" && shift) setShift(false);
  });

  return (
    <div className="hidden lg:flex flex-col gap-1 w-[980px] mx-auto">
      {showModal && (
        <Modal onClick={() => setShowModal(false)}>
          <div>
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              The virtual keyboard is consuming a lot of resources. <br />
              Showing the virtual keyboard might decrease the performance of the
              website
            </p>
            <br />
            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              if your device aspects are low please disable the virtual keyboard
              by clicking on the keyboard icon on top of the page, thank you.
            </p>
          </div>
        </Modal>
      )}
      {keyboardLayout[capsLock ? (shift ? 3 : 2) : shift ? 1 : 0].map(function (
        row: Array<string>,
        index: number
      ) {
        return (
          <div key={index} className="flex gap-1">
            {row.map((Key: string, index: number) => (
              <KeyboardKey Key={Key} key={index} radius={0.6} />
            ))}
          </div>
        );
      })}
    </div>
  );
}

export default Keyboard;
