import React from "react";
import "./index.css";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ChartTooltip from "../ChartTooltip/ChartTooltip";

function Chart({ speed }: { speed: number[] }) {
  const data = speed.map((e, i) => {
    return {
      name: `${i + 1}`,
      WPM: Math.ceil(e * (60 / (i + 1))),
    };
  });
  return (
    <div className="w-full h-full">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={200}
          height={10}
          data={data}
          margin={{
            top: 10,
            right: 0,
            left: -33,
            bottom: 0,
          }}
        >
          <XAxis dataKey="name" minTickGap={80} name="Seconde" />
          <YAxis />
          <Tooltip
            content={<ChartTooltip />}
            cursor={{ fill: "transparent" }}
          />
          <defs>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="10%" stopColor="#34d399" stopOpacity={1} />
              <stop offset="90%" stopColor="#34d399" stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="WPM"
            stroke="#34d399"
            strokeOpacity={0.3}
            fill="url(#colorUv)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}

export default Chart;
