export default function wrongWords(text: string, written: string): number {
  const writtenWords = written.split(" ");
  const writtenLetters = written.split("");
  const textWords = text.split(" ");
  const textLetters = text.split("");
  if (
    writtenWords[writtenWords.length - 1] !== textWords[writtenWords.length - 1]
  )
    writtenWords.pop();
  let cp = 0;
  for (let i = 0; i < writtenLetters.length; i++) {
    if (writtenLetters[i] !== textLetters[i]) {
      cp++;
      if (textLetters[i] !== " ") {
        while (textLetters[i] !== " ") {
          i++;
        }
      }
    }
  }
  return cp;
}
