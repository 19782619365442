enum ACTIONS {
  FETCHTEXT,
  LOADING,
  RELOAD,
  COUNTDOWN,
  WRITE,
  SETSTATS,
  ADDSPEED,
  UNMOUNT,
}

export default ACTIONS;
