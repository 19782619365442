export default function writtenWords(text: string, written: string): number {
  const writtenWords = written.split(" ");
  const writtenLetters = written.split("");
  const textWords = text.split(" ");
  const textLetters = text.split("");
  if (
    writtenWords[writtenWords.length - 1] !== textWords[writtenWords.length - 1]
  )
    writtenWords.pop();
  let cp = 0;
  let wrong = false;
  for (let i = 0; i < writtenLetters.length; i++) {
    if (writtenLetters[i] === textLetters[i]) {
      if (textLetters[i + 1] === " ") if (!wrong) cp++;
      wrong = false;
    } else wrong = true;
  }
  return cp;
}
