import TestContext from "../../context/TestContext";
import TestComponent from "../../components/Test/Test";
import Modal from "../../components/Modal/Modal";

function Test() {
  const isMobile = /android|iphone|kindle|ipad/i.test(navigator.userAgent);
  return (
    <div className="dark:bg-slate-900 w-screen h-screen overflow-hidden">
      {isMobile ? (
        <Modal>
          <div>
            <p>
              This test is designed for larger screens and it's not supporting
              your screen
            </p>
            <p>
              please reopen the website from larger screen so you can access it
            </p>
          </div>
        </Modal>
      ) : (
        <TestContext>
          <TestComponent />
        </TestContext>
      )}
    </div>
  );
}

export default Test;
