import isMultipleOf from "./isMultipleOf";
import lastSpace from "./lastSpace";

export default function breakLineIndexes(
  text: string,
  lineWidth: number
): Array<number> {
  const indexes: Array<number> = [];
  for (let i = 0; i <= text.length; i++) {
    if (isMultipleOf(i, lineWidth)) {
      indexes.push(lastSpace(text, i));
    }
  }
  return indexes;
}
