import { createContext, ReactNode, useEffect, useReducer } from "react";
import ACTIONS from "../types/actions";
import fetchText from "../logic/fetchText";
import action from "../interfaces/testTextReducerAction";
import testTextuseReducer from "../interfaces/testTextuseReducer";
import context from "../interfaces/testTextContext";
import wrongWords from "../logic/wrongWords";
import wrongLetters from "../logic/wrongLetters";
import writtenWords from "../logic/writtenWords";
import { useNavigate } from "react-router-dom";

const init: testTextuseReducer = {
  time: 60,
  text: "",
  written: "",
  wrongLetters: 0,
  wrongWords: 0,
  writtenWords: 0,
  speed: [],
  isLoading: true,
};

export const Context = createContext<context>({
  testText: init,
  dispatch: () => {},
});
const reducer = (state: testTextuseReducer, action: action) => {
  switch (action.type) {
    case ACTIONS.FETCHTEXT:
      return { ...state, isLoading: false, text: action.payload?.text || "" };
    case ACTIONS.LOADING:
      return { ...state, isLoading: true };
    case ACTIONS.RELOAD:
      return { ...init };
    case ACTIONS.COUNTDOWN:
      if (state.time === 0) return { ...state, time: state.time };
      return { ...state, time: state.time - 1 };
    case ACTIONS.WRITE:
      return { ...state, written: action.payload?.written || state.written };
    case ACTIONS.SETSTATS:
      return {
        ...state,
        wrongWords: action.payload?.wrongWords || state.wrongWords,
        wrongLetters: action.payload?.wrongLetters || state.wrongLetters,
        writtenWords: action.payload?.writtenWords || state.writtenWords,
      };
    case ACTIONS.ADDSPEED:
      return {
        ...state,
        speed: [...state.speed, state.writtenWords],
      };
    case ACTIONS.UNMOUNT:
      return {
        ...init,
        text: state.text,
      };
    default:
      return state;
  }
};

function TextContext({ children }: { children: ReactNode }) {
  const [testText, dispatch] = useReducer(reducer, init);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch({ type: ACTIONS.LOADING });
    const f = async () => {
      const text = await fetchText();
      dispatch({ type: ACTIONS.FETCHTEXT, payload: { text: await text } });
    };
    f();
    return () => dispatch({ type: ACTIONS.UNMOUNT });
  }, []);
  useEffect(() => {
    const { text, written } = testText;
    const wrongW = wrongWords(text, written);
    const wrongL = wrongLetters(text, written);
    const writtenW = writtenWords(text, written);
    dispatch({
      type: ACTIONS.SETSTATS,
      payload: {
        wrongWords: wrongW,
        wrongLetters: wrongL,
        writtenWords: writtenW,
      },
    });
  }, [testText.written]);
  useEffect(() => {
    const { writtenWords, wrongWords, speed } = testText;
    const data = {
      writtenWords,
      wrongWords,
      speed,
    };
    if (testText.time === 0) {
      navigate("/result", { state: data });
    }
  }, [testText.time]);
  return (
    <Context.Provider value={{ testText, dispatch }}>
      {children}
    </Context.Provider>
  );
}

export default TextContext;
