import { useState } from "react";
import specialKeysWidth from "../specialKeysWidth";

function CapsLock({ Key, radius }: { Key: string; radius: number }) {
  const [capsLock, setCapLock] = useState(false);
  window.addEventListener("keydown", (e) => {
    setCapLock(e.getModifierState("CapsLock"));
  });

  return (
    <div
      className={`border-2 dark:border-slate-500 rounded-md h-[64px] flex items-center justify-center ${
        capsLock && "key-bg"
      }`}
      style={{
        width: specialKeysWidth(Key),
        borderRadius: `${radius}rem`,
      }}
    >
      <p>{Key}</p>
    </div>
  );
}

export default CapsLock;
