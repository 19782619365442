function ChartTooltip({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    return (
      <div className="rounded-full">
        <p>
          Second : <span className="text-lg">{payload[0].payload.name}</span>
        </p>
        <p>
          Speed : <span className="text-lg">{payload[0].payload.WPM}</span> WPM
        </p>
      </div>
    );
  }

  return null;
}

export default ChartTooltip;
