export default async function fetchText(): Promise<string> {
  const text: string[] = [];
  const id: string[] = [];
  let i = 0;
  while (i < 3) {
    const res = await fetch("https://api.quotable.io/random?minLength=200");
    const data = await res.json();
    if (id.length === 0) {
      text.push(data.content);
      id.push(data._id);
      i++;
    } else {
      if (!id.includes(data.id)) {
        text.push(data.content);
        id.push(data._id);
        i++;
      }
    }
  }
  return text.join(" ");
}
