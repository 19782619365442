export default function specialKeysWidth(key: string): string {
  switch (key) {
    case "backspace":
      return "96px";
    case "tab":
      return "96px";
    case "\\":
      return "72px";
    case "|":
      return "72px";
    case "enter":
      return "100px";
    case "capsLock":
      return "128px";
    case "shift":
      return "148px";
    case "ctrl":
      return "10%";
    case "alt":
      return "10%";
    case "space":
      return "60%";
    default:
      return "64px";
  }
}
