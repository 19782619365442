import "./index.css";

function Results({
  speed,
  writtenWords,
  wrongWords,
}: {
  speed: number;
  writtenWords: number;
  wrongWords: number;
}) {
  const accuracy = Math.round(
    100 - (wrongWords / (writtenWords + wrongWords)) * 100
  );
  return (
    <div className="min-w-[250px] wpm h-full flex flex-col items-start justify-start">
      <div className="text-[40px] relative font-bold">
        <p className="text-[100px]">{speed} </p>
        <p className="absolute right-0 bottom-[-15px]">WPM</p>
      </div>
      <p className="text-2xl mt-5">
        <span className="text-4xl"> {accuracy}%</span> Accuracy
      </p>
      <p className="text-2xl">
        <span className="text-4xl">{writtenWords + wrongWords}</span> written
        words
      </p>
      <p className="text-2xl">
        <span className="text-4xl">{writtenWords}</span> Correct words
      </p>
      <p className="text-2xl">
        <span className="text-4xl">{wrongWords}</span> wrong words
      </p>
    </div>
  );
}

export default Results;
