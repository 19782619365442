import { useLocation } from "react-router-dom";
import Chart from "../../components/Chart/Chart";
import Header from "../../components/Header/Header";
import Results from "../../components/Results/Results";
import showRealSpeed from "../../logic/showRealSpeed";

function Result() {
  const location = useLocation();
  const data: {
    writtenWords: number;
    speed: number[];
    wrongWords: number;
  } = location.state;
  return (
    <div className="h-screen w-screen flex dark:bg-slate-900 items-center justify-center">
      <Header />
      {data ? (
        <div className="flex flex-col md:flex-row items-start p-4 h-[calc(100%-72px)] gap-3 justify-center w-screen max-w-[990px] md:h-[400px] md:mx-auto mt-[72px]">
          <Results
            writtenWords={data.writtenWords}
            wrongWords={data.wrongWords}
            speed={showRealSpeed(data.speed)}
          />
          <Chart speed={data.speed} />
        </div>
      ) : (
        <p>Something is wrong :(</p>
      )}
    </div>
  );
}

export default Result;
