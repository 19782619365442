import { MouseEventHandler, useContext, useState } from "react";
import DarkModeButton from "../DarkModeButton/DarkModeButton";
import Reload from "../Reload/Reload";
import { Context } from "../../context/TestContext";
import ACTIONS from "../../types/actions";
import fetchText from "../../logic/fetchText";
import { Link } from "react-router-dom";
import ShowKeyboard from "../ShowKeyboard/ShowKeyboard";

function Header({
  reload,
  keyboard,
}: {
  reload?: boolean;
  keyboard?: MouseEventHandler<HTMLButtonElement>;
}) {
  const { dispatch } = useContext(Context);
  const handleReload = async () => {
    dispatch({ type: ACTIONS.RELOAD });
    const text = await fetchText();
    dispatch({ type: ACTIONS.FETCHTEXT, payload: { text: await text } });
  };
  return (
    <header className="border-b dark:border-slate-700 w-screen dark:bg-slate-900 fixed top-0">
      <div className="max-w-[990px] h-[72px] px-4 lg:px-0 mx-auto flex items-center justify-between">
        <Link to="/" className="logo flex items-center justify-center gap-2">
          <svg
            width="60"
            height="60"
            viewBox="0 0 360 206"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1_2)">
              <rect
                x="150"
                y="2"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="76"
                y="2"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="298"
                y="2"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="261"
                y="73"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="224"
                y="144"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="150"
                y="144"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="76"
                y="144"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="2"
                y="144"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="187"
                y="73"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="113"
                y="73"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="39"
                y="73"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
              <rect
                x="224"
                y="2"
                width="60"
                height="60"
                rx="14"
                stroke="currentColor"
                strokeWidth="4"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_2">
                <rect width="360" height="206" fill="currentColor" />
              </clipPath>
            </defs>
          </svg>

          <p>Kani Typing Master</p>
        </Link>
        <div className="buttons flex gap-2">
          <DarkModeButton />
          {reload && <Reload onClick={handleReload} />}
          {keyboard && <ShowKeyboard onClick={keyboard} />}
        </div>
      </div>
    </header>
  );
}

export default Header;
