import { Link } from "react-router-dom";
import "./styles.css";
function Welcome() {
  return (
    <div className="relative flex items-center justify-evenly h-screen flex-col">
      <video autoPlay loop muted playsInline>
        <source src="/videos/bg.mp4" type="video/mp4" />
      </video>
      <div className="flex px-4 z-10 items-center justify-center gap-5 text-white">
        <img src="/images/logo.svg" className="w-[100px]" alt="logo" />
        <h1 className="text-3xl">Welcome to Kani typing master</h1>
      </div>
      <Link
        to="test"
        className="z-10 bg-transparent hover:bg-white text-white font-light py-3 px-5 border border-white hover:border-white rounded hover:text-black hover:mix-blend-lighten"
      >
        Start a test
      </Link>
    </div>
  );
}

export default Welcome;
