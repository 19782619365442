export default function showRealSpeed(speed: number[]): number {
  let listSpeed: number[] = [];
  if (speed.length === 0) return 0;
  if (speed.length === 1) return speed[0];
  else {
    for (let i = 0; i < speed.length; i++) {
      listSpeed.push(speed[i] * (60 / (i + 1)));
    }
  }

  return listSpeed[listSpeed.length - 1];
}
