import { useEffect, useRef, useState, Fragment, useContext } from "react";
import Letter from "../Letter/Letter";
import breakLineIndexes from "./breakLineIndexes";
import { Context } from "../../context/TestContext";
import ACTIONS from "../../types/actions";

function TestText() {
  const { testText, dispatch } = useContext(Context);
  const inputRef = useRef<HTMLInputElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [text, setText] = useState("");
  const [input, setInput] = useState("");
  const [current, setCurrent] = useState(-1);
  const [brIndexes, setBrIndexes] = useState<Array<number>>([]);
  const [cursorPosition, setCursorPosition] = useState(0);
  const [lastCursorPosition, setLastCursorPosition] = useState(0);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  useEffect(() => {
    setWidth(divRef.current?.clientWidth || width);
  }, [divRef.current?.clientWidth]);
  useEffect(() => {
    setText(testText.text);
    inputRef.current?.focus();
  }, [testText.text]);
  const handleBlur = () => {
    inputRef.current?.focus();
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > input.length) setInput(e.target.value);
    else {
      setInput(e.target.value);
      setCurrent(current - 2);
    }
    input.length === 0 && !isTimerStarted && setIsTimerStarted(true);
  };
  useEffect(() => {
    if (isTimerStarted) {
      const interval = setInterval(() => {
        dispatch({ type: ACTIONS.COUNTDOWN });
        dispatch({
          type: ACTIONS.ADDSPEED,
          payload: { speed: [...testText.speed] },
        });
        if (testText.time === 0) {
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isTimerStarted, testText.time]);

  useEffect(() => {
    text.length &&
      setBrIndexes(
        breakLineIndexes(text, Math.round(width / 16 + 180 / width))
      );
  }, [text]);
  useEffect(() => {
    setCurrent(current + 1);
    dispatch({ type: ACTIONS.WRITE, payload: { written: input } });
  }, [input]);

  useEffect(() => {
    if (cursorPosition > lastCursorPosition && lastCursorPosition !== 0)
      divRef.current?.scrollBy({
        top: 32,
        behavior: "smooth",
      });
    else
      divRef.current?.scrollBy({
        top: -32,
        behavior: "smooth",
      });
    setLastCursorPosition(cursorPosition);
  }, [cursorPosition]);

  return (
    <div
      ref={divRef}
      className="h-[100px] mt-[80px] w-full overflow-y-scroll mx-auto text-2xl my-3"
    >
      <label htmlFor="input" className="opacity-0">
        HTML
      </label>
      <input
        type="text"
        id="input"
        className="opacity-0 cursor-default absolute bottom-0 left-0 w-0 h-0 z-[-1]"
        value={input}
        ref={inputRef}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <div className="test-text">
        {text.split("").map((e, i) => {
          const letterBeforeInfo =
            i === 0
              ? undefined
              : input[i - 1] === undefined
              ? "waiting"
              : "written";
          return (
            <Fragment key={i}>
              <Letter
                letter={e}
                cursorPosition={setCursorPosition}
                letterBeforeInfo={letterBeforeInfo}
                state={
                  input[i] === undefined
                    ? "waiting"
                    : input[i] === e
                    ? "written"
                    : "wrong"
                }
                index={i}
              />
              {brIndexes.includes(i) && <br />}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
}

export default TestText;
