import { useState } from "react";
import specialKeysWidth from "./specialKeysWidth";
import keyCode from "./keyCode";
import CapsLock from "./specialKeys/CapsLock";
import "./index.css";

function KeyboardKey({ Key, radius }: { Key: string; radius: number }) {
  const [keyClicked, setKeyClicked] = useState(false);
  if (Key === "capsLock") return <CapsLock Key={Key} radius={radius} />;
  window.addEventListener("keydown", (e) => {
    if (e.key === keyCode(Key)) setKeyClicked(true);
  });
  window.addEventListener("keyup", (e) => {
    if (e.key === keyCode(Key)) setKeyClicked(false);
  });

  return (
    <div
      className={`border-2 dark:border-slate-500 rounded-md h-[64px] flex items-center justify-center ${
        keyClicked && "key-bg"
      }`}
      style={{
        width: specialKeysWidth(Key),
        borderRadius: `${radius}rem`,
      }}
    >
      <p>{Key}</p>
    </div>
  );
}

export default KeyboardKey;
