export default function keyCode(key: string): string {
  switch (key) {
    case "backspace":
      return "Backspace";
    case "tab":
      return "Tab";
    case "enter":
      return "Enter";
    case "capsLock":
      return "CapsLock";
    case "shift":
      return "Shift";
    case "ctrl":
      return "Control";
    case "alt":
      return "Alt";
    case "space":
      return " ";
    default:
      return key;
  }
}
