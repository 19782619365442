import { useContext, useState } from "react";
import Header from "../../components/Header/Header";
import Keyboard from "../../components/Keyboard/Keyboard";
import TestText from "../../components/TestText/TestText";
import { Context } from "../../context/TestContext";
import Loader from "../../components/Loader/Loader";
import "./index.css";
import Stats from "../../components/Stats/Stats";

function Test() {
  const { testText } = useContext(Context);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const { isLoading, time } = testText;
  return (
    <div className="dark:bg-slate-900 h-screen relative w-screen overflow-hidden">
      <Header reload keyboard={() => setShowKeyboard(!showKeyboard)} />
      <span
        className="timer h-[3px] absolute left-[-2px] top-[71px] bg-emerald-500 rounded-full"
        style={{ width: `${((60 - time) * 100) / 60}%` }}
      ></span>
      {!isLoading ? (
        <div className="w-screen max-w-[980px] p-4 h-full lg:mx-auto flex flex-col justify-start gap-9 lg:gap-0 items-center lg:justify-evenly">
          <TestText />
          <div className="w-full flex flex-col gap-3">
            <Stats />
            {showKeyboard && <Keyboard />}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default Test;
