import { useContext } from "react";
import { Context } from "../../context/TestContext";
import showRealSpeed from "../../logic/showRealSpeed";

function Stats() {
  const { testText } = useContext(Context);
  const { time, writtenWords, wrongLetters, wrongWords, speed } = testText;
  const realSpeed = showRealSpeed(speed);
  //console.log(speed);
  return (
    <div className="flex flex-col items-start lg:flex-row justify-between w-full text-lg">
      <p>
        <span className="text-2xl font-bold">{time}</span> seconds left
      </p>
      <p>
        <span className="text-2xl font-bold">{wrongLetters}</span> wrong letters
      </p>
      <p>
        <span className="text-2xl font-bold">{wrongWords}</span> wrong words
      </p>
      <p>
        <span className="text-2xl font-bold">{writtenWords}</span> Written words
      </p>
      <p>
        <span className="text-2xl font-bold">{Math.ceil(realSpeed)}</span> WPM
      </p>
    </div>
  );
}

export default Stats;
