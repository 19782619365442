import { useEffect, useRef } from "react";
import "./index.css";

function Letter({
  letter,
  cursorPosition,
  letterBeforeInfo,
  state,
  index,
}: {
  letter: string;
  cursorPosition: Function;
  letterBeforeInfo: string | undefined;
  state: "waiting" | "written" | "wrong";
  index: number;
}) {
  const ref = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    state === "waiting" &&
      (letterBeforeInfo === "written" || letterBeforeInfo === undefined) &&
      ref &&
      ref.current &&
      cursorPosition(ref.current.offsetTop);
  }, [letterBeforeInfo, state]);
  if (state === "waiting" && letterBeforeInfo === "waiting")
    return <>{letter}</>;
  return (
    <span
      ref={ref}
      className={`relative inline h-[20px] ${
        state === "waiting" &&
        (letterBeforeInfo === "written" || letterBeforeInfo === undefined) &&
        "cursor-line"
      } ${state}${letter === " " ? "bg-space" : ""}`}
    >
      {letter}
    </span>
  );
}

export default Letter;
