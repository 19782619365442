const keyboardLayout: Array<Array<Array<string>>> = [
  [
    [
      "`",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "-",
      "=",
      "backspace",
    ],
    ["tab", "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "[", "]", "\\"],
    [
      "capsLock",
      "a",
      "s",
      "d",
      "f",
      "g",
      "h",
      "j",
      "k",
      "l",
      ";",
      "'",
      "enter",
    ],
    ["shift", "z", "x", "c", "v", "b", "n", "m", ",", ".", "/", "shift"],
    ["ctrl", "alt", "space", "alt", "ctrl"],
  ],
  [
    [
      "~",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "_",
      "+",
      "backspace",
    ],
    ["tab", "Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "{", "}", "|"],
    [
      "capsLock",
      "A",
      "S",
      "D",
      "F",
      "G",
      "H",
      "J",
      "K",
      "L",
      ":",
      '"',
      "enter",
    ],
    ["shift", "Z", "X", "C", "V", "B", "N", "M", "<", ">", "?", "shift"],
    ["ctrl", "alt", "space", "alt", "ctrl"],
  ],
  [
    [
      "`",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "0",
      "-",
      "=",
      "backspace",
    ],
    ["tab", "Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P", "[", "]", "\\"],
    [
      "capsLock",
      "A",
      "S",
      "D",
      "F",
      "G",
      "H",
      "J",
      "K",
      "L",
      ";",
      "'",
      "enter",
    ],
    ["shift", "Z", "X", "C", "V", "B", "N", "M", ",", ".", "?", "shift"],
    ["ctrl", "alt", "space", "alt", "ctrl"],
  ],
  [
    [
      "~",
      "!",
      "@",
      "#",
      "$",
      "%",
      "^",
      "&",
      "*",
      "(",
      ")",
      "_",
      "+",
      "backspace",
    ],
    ["tab", "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "{", "}", "|"],
    [
      "capsLock",
      "a",
      "s",
      "d",
      "f",
      "g",
      "h",
      "j",
      "k",
      "l",
      ":",
      '"',
      "enter",
    ],
    ["shift", "z", "x", "c", "v", "b", "n", "m", "<", ">", "?", "shift"],
    ["ctrl", "alt", "space", "alt", "ctrl"],
  ],
];

export default keyboardLayout;
